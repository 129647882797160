<template>
    <div ref="container">
        <v-card :loading="loading" class="pa-2" height="100vh">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Invoice Request {{ invoice.code }} /
                    <span class="font-weight-black text-uppercase">
                        PROJECT: {{ project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="px-4"
                :style="`height: ${height}px`"
            >
                <v-row ref="details_1">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-if="invoice.createdBy && invoice.createdBy.name"
                            v-model="invoice.createdBy.name"
                            outline
                            prefix="Created By: "
                            hide-details
                            prepend-icon="mdi-account-group-outline"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="invoice.date"
                            prepend-icon="mdi-calendar"
                            prefix="Created On: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-model="invoice.client.name"
                            outline
                            prefix="Client: "
                            hide-details
                            prepend-icon="mdi-office-building-outline"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            v-if="invoiceConcept"
                            outline
                            v-model="invoiceConcept.name"
                            prepend-icon="mdi-table-column"
                            prefix="Concept: "
                            hide-details
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_2">
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="project.currency"
                            prepend-icon="mdi-cash"
                            prefix="Currency: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            outline
                            v-model="invoice.region"
                            prepend-icon="mdi-earth"
                            prefix="Region: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            hide-details
                            v-model="invoice.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            outline
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" class="pa-2">
                        <v-text-field
                            outline
                            style="color:blue;"
                            v-model="invoice.invoiceNumber"
                            prepend-icon="mdi-receipt-text-outline"
                            prefix="Invoice No: "
                            :disabled="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed'
                            "
                            :rules="[rules.required]"
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_3">
                    <v-col
                        v-if="invoice.status == 'generating'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="px-2 pb-2 pt-0"
                    >
                        <v-file-input
                            prefix="Upload Invoice"
                            v-model="invoice.attached"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-upload"
                            accept=".pdf"
                            style="color:blue;"
                        ></v-file-input>
                        <v-chip
                            v-if="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed'
                            "
                            class="mt-1 ml-1"
                            @click="loadFile(invoice.attached)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.attached }}</span>
                        </v-chip>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'credit'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="pa-2 pt-0"
                    >
                        <v-file-input
                            prefix="Upload Credit Note"
                            v-model="credit"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-upload"
                            accept=".pdf"
                            style="color:blue;"
                            :clearable="false"
                        ></v-file-input>
                        <v-chip
                            v-if="invoice.status == 'credit' && invoice.credit"
                            class="mt-1 ml-1"
                            @click="loadFile(invoice.credit)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.credit }}</span>
                        </v-chip>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'closed'"
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="d-flex flex-wrap pa-2 pt-0"
                    >
                        <div class="d-flex mr-2" v-if="invoice.attached">
                            <h3 class="mt-5">Invoice:</h3>
                            <v-chip
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.attached)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.attached
                                }}</span>
                            </v-chip>
                        </div>
                        <div class="d-flex" v-if="invoice.credit">
                            <h3 class="mt-5">Credit note:</h3>
                            <v-chip
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.credit)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.credit
                                }}</span>
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        class="pt-0"
                        v-if="invoice.attachments"
                    >
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>Attachments: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in invoice.attachments"
                                    :key="i"
                                >
                                    <span @click="openFileAttached(file)">{{
                                        file
                                    }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="validateConcept" no-gutters>
                    <v-col cols="12" ls="2" md="2" sm="2">
                        <div class="d-flex justity-center">
                            <v-text-field
                                type="number"
                                hide-details
                                v-model="invoice.amount"
                                prepend-icon="mdi-text"
                                prefix="Amount: "
                                outline
                                readonly
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    class="quote_tables mt-3"
                >
                    <v-col
                        cols="12"
                        v-for="quoteId of Object.keys(invoice.items)"
                        :key="quoteId"
                        class="mb-6"
                    >
                        <v-data-table
                            v-if="validateInvoiceItems(invoice.items[quoteId])"
                            :headers="headers"
                            :items="invoice.items[quoteId]"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="4" class="d-flex">
                                        <h2 class="mt-1">
                                            QUOTE:
                                            {{
                                                quoteId == 'free'
                                                    ? 'free'
                                                    : getQuoteName(quoteId)
                                            }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ item.name }}
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ formatNumber(item.pricePerUnit) }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        item.billedQty ||
                                            item.invoiceQuantity ||
                                            item.creditNoteQty
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        formatNumber(
                                            (item.billedQty ||
                                                item.invoiceQuantity ||
                                                item.creditNoteQty ||
                                                0) * item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td class="font-weight-bold"></td>
                                    <td />
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            quoteTotalQty(
                                                invoice.items[quoteId]
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            formatNumber(
                                                quoteTotalInvoice(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    style="background-color: #eeeeee"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT PRICE: {{ formatNumber(project.price) }}
                        </p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT BILLED:
                            {{ formatNumber(project.totalInvoiced || 0) }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black">
                            Qty: {{ invoiceTotalQty().toFixed(2) }}
                        </p>
                        <p class="my-2 font-weight-black mr-4">
                            Current Invoice:
                            {{ formatNumber(invoiceTotalPrice()) }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-btn
                    v-if="
                        invoice.status == 'generating' &&
                            generateInvoicePermission
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openRejectForm"
                >
                    REJECT
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="invoice.status == 'generating'"
                    rounded
                    color="#27BC4F"
                    class="mb-4 mr-2 white--text"
                    @click="generate"
                    :disabled="!invoice.attached || !invoice.invoiceNumber"
                >
                    GENERATE
                </v-btn>
                <v-btn
                    v-if="invoice.status == 'credit'"
                    :disabled="!this.invoice.credit"
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openCloseInvoice = true"
                >
                    CLOSE
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Reject Work Orders-->
        <v-dialog
            v-model="rejectForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="rejectInvoiceLoading" v-if="rejectForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            invoice
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectForm">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectInvoice"
                        text
                        color="error"
                        :loading="rejectInvoiceLoading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--CLOSE INVOICE-->
        <v-dialog
            v-model="openCloseInvoice"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCloseInvoice">
                <v-card-title class="text-h5">CLOSE INVOICE</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Are you sure you want to close this invoice?
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCloseInvoice = false"
                    >
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeInvoice"
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { loadImage } from '@/helpers/imageHandler.js'

export default {
    name: 'GenerateInvoce',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
        setting: {
            type: Object,
            required: true,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
    },
    data: () => ({
        openCloseInvoice: false,
        rejectedNotes: null,
        valid: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
        },
        concepts: [],
        types: [],
        categories: [],
        regions: ['international', 'local'],
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'description',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'CATEGORY',
                value: 'category',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CURRENT INVOICE',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
        ],
        rejectForm: false,
        companyId: JSON.parse(localStorage.getItem('company')),
        rejectInvoiceLoading: false,
        credit: null,
        rejectionTable: false,
        invoiceConcept: null,
        generateInvoicePermission: false,
    }),
    inject: {
        addInvoice: {
            from: 'addInvoice',
            default() {
                return null
            },
        },
    },
    computed: {
        validateConcept() {
            if (this.invoice.concept) {
                const concept = this.getConcept(this.invoice.concept)
                if (concept) {
                    return concept.enabledValue
                }
            }
            return false
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.categories = this.setting.categories
            this.concepts = this.setting.concepts
            this.invoiceConcept = this.concepts.find(
                c => c.id == this.invoice.concept
            )
            this.types = this.setting.types
            this.invoice.date = this.formatDate(this.invoice.createdOn._seconds)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.generateInvoicePermission = user.permissions.includes(
                'generateInvoice'
            )
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async credit() {
            try {
                this.loading = true
                await saveFile(
                    this.credit,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
                this.invoice.credit = this.credit.name
                const invoice = await API.uploadCreditNote({
                    id: this.invoice.id,
                    projectId: this.project.id,
                    credit: this.invoice.credit,
                })
                this.$emit('replaceInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getConcept(id) {
            return this.concepts.find(c => c.id == id)
        },
        async closeInvoice() {
            try {
                this.loading = true
                const invoice = await API.closeInvoice({
                    projectId: this.invoice.projectId,
                    id: this.invoice.id,
                })
                this.$emit('replaceInvoice', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.projectId}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        invoiceTotalPrice() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (
                        this.invoice.status != 'billed' &&
                        this.invoice.status != 'closed' &&
                        this.invoice.status != 'approved'
                    ) {
                        if (cost.invoiceQuantity) {
                            total += cost.invoiceQuantity * cost.pricePerUnit
                        }
                    } else {
                        total += (cost.billedQty || 0) * cost.pricePerUnit
                    }
                }
            }
            return total
        },
        invoiceTotalQty() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (
                        this.invoice.status != 'billed' &&
                        this.invoice.status != 'closed' &&
                        this.invoice.status != 'approved'
                    ) {
                        if (cost.invoiceQuantity) {
                            total += cost.invoiceQuantity
                        }
                    } else {
                        total += cost.billedQty || 0
                    }
                }
            }
            return total
        },
        projectBilled() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    total += (cost.billedQty || 0) * cost.pricePerUnit
                }
            }
            return total
        },
        quoteTotalInvoice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.invoiceQuantity ||
                            cost.billedQty ||
                            cost.creditNoteQty ||
                            0),
                0
            )
        },
        quoteTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    (cost.invoiceQuantity ||
                        cost.billedQty ||
                        cost.creditNoteQty ||
                        0),
                0
            )
        },
        getQuoteName(quoteId) {
            const quote = this.project.quotes.find(q => q.id == quoteId)
            if (quote) {
                return quote.name
            }
            return quoteId
        },
        formatNumber(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: this.invoice.currency ? 2 : 0,
            }).format(number)
        },
        async generate() {
            try {
                this.loading = true
                //save file
                if (!this.invoice.attached || !this.invoice.attached.name) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: 'file not found',
                    })
                    return null
                }
                await saveFile(
                    this.invoice.attached,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
                // generate invoice
                const invoice = await API.generateInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    attached: this.invoice.attached.name,
                    invoiceNumber: this.invoice.invoiceNumber,
                    notificationId: '2o3QIGSpJM5Zb1Fpd8jW',
                    settingId: this.setting.id,
                })
                this.$emit('generate', invoice)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async rejectInvoice() {
            try {
                this.rejectInvoiceLoading = true
                const invoice = await API.rejectInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    notes: this.rejectedNotes,
                    notificationId:
                        this.invoice.region == 'local'
                            ? 'bgOJPzzceetopCQj5kxs'
                            : 'oEZbNBsm7NuoohIWRRKo',
                })
                this.rejectForm = false
                this.$emit('removeInvoice', invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.rejectInvoiceLoading = false
            }
        },
        closeRejectForm() {
            this.rejectForm = false
        },
        openRejectForm() {
            this.rejectForm = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - 80
        },
        close() {
            this.$emit('close')
        },
        sendInvoice() {
            this.addInvoice()
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validateInvoiceItems(costs) {
            if (costs.length == 0) {
                return false
            }
            let response = false
            for (const cost of costs) {
                if (
                    this.inProcess ||
                    this.invoice.status == 'international' ||
                    this.invoice.status == 'generating' ||
                    this.invoice.status == 'rejected'
                ) {
                    if (cost.invoiceQuantity) {
                        response = true
                        break
                    }
                } else if (this.invoice.status == 'credit') {
                    if (cost.invoiceQuantity || cost.creditNoteQty) {
                        response = true
                        break
                    }
                } else if (this.invoice.status == 'closed') {
                    if (
                        cost.billedQty ||
                        cost.invoiceQuantity ||
                        cost.creditNoteQty
                    ) {
                        response = true
                        break
                    }
                } else {
                    if (cost.billedQty) {
                        response = true
                        break
                    }
                }
            }
            return response
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: scroll;
}
</style>
